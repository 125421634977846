"use client";
import React, { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import SearchBoxWithResults from "@/components/SearchBoxWithResults/SearchBoxWithResults";
import SearchData from "@/data/datasearch.json";
import "bootstrap/dist/css/bootstrap.min.css";
import "./header.css";

function Header() {
  const [isBottomRowVisible, setIsBottomRowVisible] = useState(true);
  const [lastScrollPosition, setLastScrollPosition] = useState(0);
  const trUrl = "/assets/tr.svg";
  const enUrl = "/assets/us.svg";
  const logoUrl = "/assets/favicon.ico";

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setIsBottomRowVisible(scrollY < lastScrollPosition || scrollY === 0);
      setLastScrollPosition(scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollPosition]);

  return (
    <header>
      <Nav className="header-element" variant="tabs" defaultActiveKey="/">
        <div className="top-row">
          <div className="top-left" id="top-search-logo">
            <a href="/">
              <div>
                <img src={logoUrl} alt="Website Logo" className="logo" />
              </div>
            </a>

            <Nav.Item id="home">
              <Nav.Link href="/">Anasayfa</Nav.Link>
            </Nav.Item>
          </div>
          <SearchBoxWithResults data={SearchData} className="Header-Search" />

          <div className="top-right">
            {/* Dil/Bölge Dropdown Menüsü */}
            <NavDropdown
              className="language-dropdown" // CSS sınıfı atama
              title={
                <span>
                  <img
                    src={trUrl}
                    alt="Türkçe"
                    style={{ width: "2rem", marginRight: "10px" }}
                  />
                  TR
                </span>
              }
            >
              <div className="nav-dropdown-language">
                <NavDropdown.Item href="https://www.uscmarkets.com/">
                  <img src={trUrl} alt="Turkish" />
                  TR
                </NavDropdown.Item>
                <NavDropdown.Item href="https://en.uscmarkets.com/">
                  <img src={enUrl} alt="English" />
                  EN
                </NavDropdown.Item>
              </div>
            </NavDropdown>
          </div>
        </div>

        <div className={`bottom-row ${isBottomRowVisible ? "visible" : ""}`}>
          <NavDropdown
            title="Türkiye Yatırımları"
            id="nav-dropdown-turkey-investments"
          >
            <NavDropdown.Item href="/borsa-istanbul">
              Borsa İstanbul
            </NavDropdown.Item>
            <NavDropdown.Item href="/tefas-fon">Tefas Fon</NavDropdown.Item>
          </NavDropdown>

          {/* Global Yatırımlar Dropdown Menüsü */}
          <NavDropdown
            title="Global Yatırımlar"
            id="nav-dropdown-global-investments"
          >
            <NavDropdown.Item href="/vadeli-emtia">
              Vadeli Emtia
            </NavDropdown.Item>
            <NavDropdown.Item href="/sp500">SP500</NavDropdown.Item>
            <NavDropdown.Item href="/kripto">Kripto</NavDropdown.Item>
            <NavDropdown.Item href="/doviz">Döviz</NavDropdown.Item>
          </NavDropdown>

          {/* Hisse Önerileri Dropdown Menüsü */}
          <NavDropdown title="Hisse Önerileri" id="nav-dropdown-stock-tips">
            <NavDropdown.Item href="/yapay-zeka-borsa-oneri-tablolari">
              Teknik Yapay Zeka
            </NavDropdown.Item>
            <NavDropdown.Item href="/finansal-yapay-zeka-borsa-oneri-tablolari">
              Finansal Yapay Zeka
            </NavDropdown.Item>
            <NavDropdown.Item href="/teknik-analiz-borsa-oneri-tablolari">
              Teknik Analiz
            </NavDropdown.Item>
            <NavDropdown.Item href="/temel-analiz-hisse-oneri-tablolari">
              Temel Analiz
            </NavDropdown.Item>
          </NavDropdown>
        </div>
      </Nav>
    </header>
  );
}

export default Header;
